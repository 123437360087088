import React, { useState } from 'react'

// layouts
import Layout from 'src/layouts/BaseLayout'
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import AtivacaoModal1 from 'src/components/UI/Forms/InterAtivacaoClientesForm1'
import { Modal } from 'src/components/Modal'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

// Sections
import Header from '../../acesso/sections/header/_index'
import NaoLembraOsDados from '../../acesso/sections/NaoLembraOsDados/_index'
import AtualizarEndereco from '../../acesso/sections/atualizarEndereco/_index'
import HelpCenter from '../../helpCenter/_index'

import pageContext from './pageContext.json'

import { Wrapper } from '../../style'

const Acesso = () => {
  const page = 'MEI'
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModal1, setOpenModal1 ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )

  const openAccountPJFormModal1 = domReady && (
    <Modal isModalOpen={openModal1} setIsModalOpen={setOpenModal1} locationToRender={document.body}>
      <AtivacaoModal1 closeModal={() => setOpenModal1(false)} page={page} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountPJFormModal}
        {openAccountPJFormModal1}
        <Header page={page} setOpenModal1={setOpenModal1} />
        <NaoLembraOsDados page={page} />
        <AtualizarEndereco page={page} />
        <HelpCenter bgColor='bg-white' />
      </Layout>
    </Wrapper>
  )
}

export default Acesso
